import clsx from "classnames"
import type {
  PropsWithChildren,
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  MouseEvent,
  MouseEventHandler,
} from "react"

interface ButtonBaseProps {
  primary?: boolean
  size?: "small" | "medium" | "large"
  wide?: boolean
  onDark?: boolean
  icon?: React.ReactNode
}

export type ButtonOnClickEvent = MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>

type AnchorButtonProps = ButtonBaseProps & {
  onClick?: MouseEventHandler<HTMLAnchorElement>
  /**
   * Custom class names to apply to the button.
   */
  className?: string
} & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "className" | "onClick">

type NativeButtonProps = ButtonBaseProps & {
  href: string
  onClick?: MouseEventHandler<HTMLButtonElement>
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className" | "onClick">

type ButtonProps = Partial<AnchorButtonProps & NativeButtonProps>

export const Button = ({
  primary,
  size = "medium",
  wide,
  onDark,
  href,
  children,
  icon,
  className,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const classes = clsx(
    "inline-flex gap-1 font-bold outline-none ring-blue-600 ring-offset-2 hover:drop-shadow-md focus:ring-2",
    {
      "border border-white bg-gray-700 text-white hover:border-white hover:bg-gray-800 focus:bg-gray-800":
        (primary && !onDark) || (!primary && onDark),
      "bg-white text-black hover:border-gray-800 hover:bg-gray-50 focus:bg-gray-50":
        (!primary && !onDark) || (primary && onDark),
      "border-2 border-gray-700": !primary && !onDark,
      "min-w-28": wide,
      "text-2xs rounded py-0.5 px-1.5": size === "small",
      "text-sm": size !== "small",
      "rounded-lg py-2 px-6": size === "large",
      "rounded py-0.5 px-4": size === "medium",
    },
    className,
  )

  const innerContent = (
    <>
      {icon && <div className="-ml-1.5 mr-0.5">{icon}</div>}
      <span>{children}</span>
    </>
  )

  return href ? (
    <a href={href} className={classes} {...rest}>
      {innerContent}
    </a>
  ) : (
    <button className={classes} {...rest}>
      {innerContent}
    </button>
  )
}
