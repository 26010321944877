// This URL pattern matches the current PageBuilder page schema format.
export const VALID_URL_PATTERN = "^(https?:\\/\\/.+\\.[a-zA-Z]{2,}|mailto:).*$"

export const isValidUrl = (urlString?: string) => {
  if (typeof urlString !== "string") {
    return false
  }

  try {
    const url = new URL(urlString)
    if (url.protocol === "javascript:") {
      return false
    }
    return true
  } catch (error) {}
  return false
}
